<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <div class="card">
        <Panel header="Importar Arquivo" class="mt-3">
          <form>
            <div class="p-fluid formgrid grid">
              <div class="field col-3 md:col-3">
                <label for="tiposArquivos">Tipo de Arquivo</label>
                <Dropdown
                  id="tiposArquivos"
                  v-model="v$.tipo.$model"
                  :class="{
                    'p-invalid': submitted && v$.tipo.$invalid,
                  }"
                  optionLabel="label"
                  :options="tiposArquivos"
                  :filter="true"
                  filterPlaceholder="Procure tipo"
                  :emptyFilterMessage="'Nenhum tipo encontrado'"
                  placeholder="Selecione um tipo">
                </Dropdown>
                <div
                  v-if="submitted && v$.tipo.required.$invalid"
                  class="p-error">
                  O campo tipo é obrigatório.
                </div>
              </div>
              <div class="field col-3 md:col-3">
                <label for="entidade">Entidade</label>
                <Dropdown
                  id="entidade"
                  v-model="v$.entidade.$model"
                  :class="{
                    'p-invalid': submitted && v$.entidade.$invalid,
                  }"
                  optionLabel="nome"
                  :options="entidades"
                  :filter="true"
                  filterPlaceholder="Procure pelo nome da entidade"
                  :emptyFilterMessage="'Nenhuma entidade encontrada'"
                  placeholder="Selecione uma entidade">
                </Dropdown>
                <div
                  v-if="submitted && v$.entidade.required.$invalid"
                  class="p-error">
                  O campo entidade é obrigatório.
                </div>
              </div>
              <div class="field col-3 md:col-3">
                <label for="anoReferencia">Ano</label>
                <InputMask
                  id="anoReferencia"
                  v-model="v$.anoReferencia.$model"
                  mask="9999"
                  :class="{
                    'p-invalid': submitted && v$.anoReferencia.$invalid,
                  }" />
                <div
                  v-if="submitted && v$.anoReferencia.required.$invalid"
                  class="p-error">
                  O campo ano é obrigatório
                </div>
                <div
                  v-if="submitted && v$.anoReferencia.validarAno.$invalid"
                  class="p-error">
                  O campo ano não pode ser menor do que o ano atual. Só é
                  possível preencher o ano anterior ao ano atual, se o mês
                  corrente for 01.
                </div>
              </div>
              <div class="field col-3 md:col-3">
                <label for="mesReferencia">Mês (1 até 12)</label>
                <InputMask
                  id="mesReferencia"
                  v-model="v$.mesReferencia.$model"
                  mask="99"
                  :class="{
                    'p-invalid': submitted && v$.mesReferencia.$invalid,
                  }" />

                <div
                  v-if="submitted && v$.mesReferencia.required.$invalid"
                  class="p-error">
                  O campo mês é obrigatório
                </div>
                <div
                  v-if="submitted && v$.mesReferencia.numeroPermitido.$invalid"
                  class="p-error">
                  O campo mês deve ser entre 01 e 12
                </div>
                <div
                  v-if="submitted && v$.mesReferencia.validarMes.$invalid"
                  class="p-error">
                  O campo mês só pode ser preenchido entre o mês anterior e o
                  mês atual
                </div>
              </div>
            </div>
            <ProgressBar
              v-if="uploading"
              mode="indeterminate"
              style="height: 0.5em" />
            <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-12">
                <FileUpload
                  id="fileUpload"
                  ref="fileUpload"
                  name="file[]"
                  accept=".text,.txt"
                  :maxFileSize="500000000"
                  :customUpload="true"
                  chooseLabel="Escolher arquivo"
                  uploadLabel="Enviar arquivo"
                  :showCancelButton="false"
                  :invalidFileTypeMessage="'{0}: Tipo de arquivo inválido, tipos de arquivo permitidos: {1}'"
                  :invalidFileSizeMessage="'{0}: Tamanho de arquivo inválido, tamanho máximo permitido: {1}'"
                  :invalidFileLimitMessage="'{0}: Número máximo de arquivos excedido, número máximo de arquivos permitidos: {1}'"
                  :fileLimit="1"
                  @uploader="validate">
                  <template #empty>
                    <p>Selecione um arquivo para importar.</p>
                  </template>
                </FileUpload>
              </div>
            </div>
          </form>
        </Panel>
        <Panel header="Arquivos Importados" class="mt-3">
          <DataTable
            class="p-datatable-sm"
            :paginator="true"
            :rows="5"
            stripedRows
            :value="arquivos"
            dataKey="id"
            :filters.sync="filtros"
            :globalFilterFields="[
              'nomeArquivo',
              'mesReferencia',
              'anoReferencia',
              'statusProcessamento.mensagem',
              'entidade.nome',
              'tipo',
            ]"
            filterDisplay="menu"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="rowsPerPageOptions"
            currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} arquivos"
            responsiveLayout="scroll">
            <template #empty> Nenhuma arquivo encontrado. </template>
            <template #loading> Carregando. Por favor aguarde. </template>
            <template #header>
              <div class="flex flex-column sm:flex-row">
                <span class="p-input-icon-left mb-2 mr-2">
                  <i class="pi pi-search" />
                  <InputText
                    v-model="filtros['global'].value"
                    placeholder="Pesquisar"
                    style="width: 100%" />
                </span>
                <Button
                  type="button"
                  icon="pi pi-filter-slash"
                  label="Limpar"
                  class="p-button-outlined mb-2"
                  @click="limparFiltro" />
              </div>
            </template>

            <Column :sortable="true" field="mesReferencia" header="Mês/Ano">
              <template #body="{ data }">
                {{ ('0' + data.mesReferencia).slice(-2) }}/{{
                  data.anoReferencia
                }}
              </template>
            </Column>
            <Column :sortable="true" field="tipo" header="Tipo de Arquivo" />
            <Column :sortable="true" field="nomeArquivo" header="Nome" />
            <Column :sortable="true" field="entidade.nome" header="Entidade" />
            <Column
              :sortable="true"
              field="statusProcessamento.mensagem"
              header="Status do Processamento" />
            <Column header="Ações">
              <template #body="{ data }">
                <Button
                  title="Arquivo"
                  label="Arquivo"
                  icon="pi pi-download"
                  class="mr-2 mb-2 p-button-sucess"
                  @click="baixarArquivo(data.nomeArquivo)" />
                <Button
                  v-if="exibirBotaoRelatorioCritica(data)"
                  title="Arquivo Crítica"
                  label="Arquivo Crítica"
                  icon="pi pi-download"
                  class="mr-2 mb-2 p-button-danger"
                  @click="baixarRelatorioCritica(data)" />
                <Button
                  v-if="exibirBotaoSolicitarProcessmento(data)"
                  :disabled="data.processando"
                  class="mr-2 mb-2 p-button-warning"
                  @click="confirmarSolicitarProcessamento(data)">
                  <span
                    v-if="data.processando"
                    class="pi pi-spin pi-spinner"></span>
                  <span v-if="!data.processando" class="ml-2"
                    >Solicitar Processamento</span
                  >
                  <span v-if="data.processando" class="ml-2">Aguarde</span>
                </Button>
              </template>
            </Column>
          </DataTable>
        </Panel>
        <ModalConfirmarOperacao
          :exibir="exibirModalConfirmarOperacao"
          :metodo="solicitarProcessamento"
          :msg="'Deseja solicitar o processamento?'"
          @mudarVisibilidade="
            exibirModalConfirmarOperacao = $event
          "></ModalConfirmarOperacao>
      </div>
    </div>
  </div>
</template>

<script>
import { Client } from '@stomp/stompjs'
import * as SockJS from 'sockjs-client'
import { saveAs } from 'file-saver'
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import FiltroImportarArquivo from '@/domain/FiltroImportarArquivo.js'
import EntidadeService from '@/service/EntidadeService.js'
import ConsignatariaService from '@/service/ConsignatariaService.js'
import ApiCargaService from '@/service/ApiCargaService.js'
import { consignatariaStore } from '@/stores/consignataria'
import ModalConfirmarOperacao from '@/components/shared/modal/modalConfirmarOperacao.vue'
import Config from '@/config'

const validarAno = (value) => validarCampoAno(value)
const validarMes = (value) => validarCampoMes(value)

function validarCampoAno(value) {
  let hoje = new Date()
  let anoAnterior = hoje.getFullYear() - 1
  let anoAtual = hoje.getFullYear()
  let mesAnterior = hoje.getMonth() == 0 ? 12 : hoje.getMonth()

  if (value) {
    if (mesAnterior == 12) {
      return value >= anoAnterior ? true : false
    } else {
      return value >= anoAtual ? true : false
    }
  }
}

function validarCampoMes(value) {
  let hoje = new Date()
  let mesAnterior = hoje.getMonth() == 0 ? 12 : hoje.getMonth()
  let mesAtual = hoje.getMonth() + 1

  if (value) {
    if (mesAnterior == 12) {
      return value == mesAnterior || value == mesAtual ? true : false
    } else {
      return value >= mesAnterior && value <= mesAtual ? true : false
    }
  }
}

const numeroPermitido = (value) =>
  validarCampoMesReferenciaNumeroPermitido(value)

function validarCampoMesReferenciaNumeroPermitido(value) {
  return value && value > 0 && value <= 12 ? true : false
}

export default {
  components: {
    ModalConfirmarOperacao,
  },

  setup() {
    const store = consignatariaStore()
    return { v$: UseVuelidate(), store }
  },

  data() {
    return {
      filtroImportarArquivo: new FiltroImportarArquivo(),
      arquivo: null,
      file: [],
      entidade: null,
      anoReferencia: null,
      mesReferencia: null,
      entidades: [],
      submitted: false,
      arquivos: [],
      tiposArquivos: [
        { nome: 'CARTAO', label: 'Cartão' },
        { nome: 'REAJUSTE', label: 'Reajuste' },
      ],
      tipo: '',
      totalRecords: 0,
      exibirModalConfirmarOperacao: false,
      arquivoASerProcessado: null,
      uploading: false,
      filtros: {},
      loading: false,
      parametrosRelatorioCritica: {
        loteId: null,
        consignatariaId: null,
        entidadeId: null,
        anoReferencia: null,
        mesReferencia: null,
        tipo: null,
      },
      client: Client,
      CARTAO: 'CARTAO',
      REAJUSTE: 'REAJUSTE',
      STATUS_PROCESSAMENTO_LOTE_SALVO: 'Lote Salvo',
    }
  },

  validations() {
    return {
      tipo: { required },
      entidade: { required },
      anoReferencia: {
        required,
        validarAno,
      },
      mesReferencia: {
        required,
        numeroPermitido,
        validarMes,
      },
    }
  },

  computed: {
    rowsPerPageOptions() {
      if (this.totalRecords < 5) {
        return null
      }
      return [5, 10, 25]
    },
  },

  created() {
    this.entidadeService = new EntidadeService(this.$http)
    this.consignatariaService = new ConsignatariaService(this.$http)
    this.ApiCargaService = new ApiCargaService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.carregarEntidade()
    this.carregarAnoMesAtual()
    this.carregarArquivos()
  },

  methods: {
    conectarWebsocket() {
      this.client = new Client()
      this.client.webSocketFactory = () => {
        return new SockJS(Config.BASE_URL + '/ws')
      }
      this.client.connectHeaders = {
        Authorization: `Bearer ${this.$auth.token}`,
      }
      this.client.onConnect = () => {
        this.client.subscribe('/carga/msg', (data) => {
          const lote = JSON.parse(data.body)

          if (lote && lote.status === '21') {
            const index = this.arquivos.findIndex(
              (el) => el.id == Number(lote.lote_id),
            )
            this.arquivos[index].processando = false
            this.arquivos[index].statusProcessamento.id = lote.status
            this.arquivos[index].statusProcessamento.mensagem = lote.msg
            this.desconectarWebsocket()
          }

          if (lote) {
            const index = this.arquivos.findIndex(
              (el) => el.id == Number(lote.lote_id),
            )

            this.arquivos[index].statusProcessamento.id = lote.status
            this.arquivos[index].statusProcessamento.mensagem = lote.msg
          }
        })
        this.client.publish({
          destination: '/app/msg',
          body: 'Conexão com o socket.',
        })
      }
      this.client.activate()
    },

    desconectarWebsocket() {
      if (this.client !== null) {
        this.client.deactivate()
      }
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },

        'statusProcessamento.mensagem': {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },

        nomeArquivo: {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
        },
      }
    },

    limparFiltro() {
      this.initFiltros()
    },

    carregarEntidade() {
      this.entidadeService.getListaEntidades().then((res) => {
        this.entidades = res
      })
    },

    carregarArquivos() {
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )

      this.ApiCargaService.getListaArquivosPorConsignataria(
        consignataria.id,
      ).then((res) => {
        this.arquivos = res
      })
    },

    carregarAnoMesAtual() {
      const date = new Date()
      this.mesReferencia = date.getMonth() + 1
      this.anoReferencia = date.getFullYear()
    },

    exibirBotaoRelatorioCritica(data) {
      return data.statusProcessamento.id != 1 &&
        (data.tipo === this.CARTAO || data.tipo === this.REAJUSTE)
        ? true
        : false
    },

    exibirBotaoSolicitarProcessmento(data) {
      return data.statusProcessamento.mensagem ===
        this.STATUS_PROCESSAMENTO_LOTE_SALVO ||
        data.statusProcessamento.mensagem.includes(
          this.STATUS_PROCESSAMENTO_LOTE_SALVO,
        )
        ? true
        : false
    },

    validate(event) {
      this.submitted = true
      this.v$.tipo.$touch()
      this.v$.entidade.$touch()
      this.v$.anoReferencia.$touch()
      this.v$.mesReferencia.$touch()

      if (
        this.v$.tipo.$invalid ||
        this.v$.entidade.$invalid ||
        this.v$.anoReferencia.$invalid ||
        this.v$.mesReferencia.$invalid
      ) {
        return
      } else {
        this.arquivo = event.files[0]
        this.enviar()
      }
    },

    enviar() {
      this.atriuirValores()
      this.uploading = true
      this.ApiCargaService.importarArquivo(
        this.filtroImportarArquivo,
        this.arquivo,
      )
        .then(() => {
          this.exibeToast('success')
          this.limparCamposAposUpload()
        })
        .catch((err) => {
          this.uploading = false
          this.$refs.fileUpload.uploadedFileCount = 0
          this.exibeToast('error', err.response.data.message)
        })
    },

    atriuirValores() {
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )
      this.filtroImportarArquivo.entidade = this.entidade
      this.filtroImportarArquivo.consignatariaId = consignataria.id
      this.filtroImportarArquivo.anoReferencia = this.anoReferencia
      this.filtroImportarArquivo.mesReferencia = this.mesReferencia
      this.filtroImportarArquivo.tipo = this.tipo.nome
    },

    limparCamposAposUpload() {
      this.uploading = false
      this.submitted = false
      this.v$.$reset()
      this.filtroImportarArquivo = new FiltroImportarArquivo()
      this.entidade = null
      this.tipo = ''
      this.consignataria = null
      this.anoReferencia = null
      this.mesReferencia = null
      this.arquivo = null
      this.initFiltros()
      this.$refs.fileUpload.clear()
      this.$refs.fileUpload.uploadedFileCount = 0
      this.carregarArquivos()
    },

    clearInputFile() {
      this.$emit('clear')
      this.$refs.fileInput.value = ''
    },

    confirmarSolicitarProcessamento(data) {
      this.arquivoASerProcessado = data
      this.exibirModalConfirmarOperacao = true
    },

    solicitarProcessamento() {
      const index = this.arquivos.findIndex(
        (el) => el.id === this.arquivoASerProcessado.id,
      )
      this.arquivos[index].processando = true

      this.ApiCargaService.solicitarProcessamento(
        this.arquivoASerProcessado,
      ).then(
        () => {
          this.limparCamposAposProcecessamento()
          this.exibeToast('success-processamento')
          this.conectarWebsocket()
        },
        (err) => {
          this.limparCamposAposProcecessamento()
          this.processando = true
          this.arquivos[index].processando = false
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    limparCamposAposProcecessamento() {
      this.exibirModalConfirmarOperacao = false
      this.arquivoASerProcessado = null
    },

    baixarArquivo(nomeArquivo) {
      this.ApiCargaService.baixarArquivo(nomeArquivo).then(
        (res) => {
          this.downloadFile(res.data, nomeArquivo)
        },
        (err) => {
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    baixarRelatorioCritica(data) {
      this.atribuirParametrosRelatorioCritica(data)
      this.ApiCargaService.baixarRelatorioCritica(
        this.parametrosRelatorioCritica,
      ).then(
        (res) => {
          this.downloadFile(
            res.data,
            res.headers['content-disposition']
              .split('filename=')[1]
              .split(';')[0],
          )
        },
        (err) => {
          this.exibeToast('error', err.response.data.message)
        },
      )
    },

    atribuirParametrosRelatorioCritica(data) {
      this.parametrosRelatorioCritica.loteId = data.id
      this.parametrosRelatorioCritica.consignatariaId = data.consignataria.id
      this.parametrosRelatorioCritica.entidadeId = data.entidade.id
      this.parametrosRelatorioCritica.anoReferencia = data.anoReferencia
      this.parametrosRelatorioCritica.mesReferencia = data.mesReferencia
      this.parametrosRelatorioCritica.tipo = data.tipo
    },

    downloadFile(response, nomeArquivo) {
      const blob = new Blob([response], { type: 'text/plain' })
      saveAs(blob, nomeArquivo)
    },

    downloadCSV(response, nomeArquivo) {
      const blob = new Blob([response], { type: 'text/csv' })
      saveAs(blob, nomeArquivo)
    },

    exibeToast(tipo, msg) {
      if (tipo === 'success') {
        this.$toast.add({
          severity: 'success',
          summary: 'Importação realizada com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      } else if (tipo === 'success-processamento') {
        this.$toast.add({
          severity: 'success',
          summary: 'Processamento solicitado.',
          life: 10000,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
button {
  margin: 0 2px;
}
</style>
